//Site modules
import {showPage} from "%import%/standalone/showPage";
import {mainMenu} from "%components%/main-menu/main-menu";
import {shareStory} from "../blocks/pages/page-home/section-share-story/section-share-story";
import {music} from "../blocks/pages/page-home/section-music/section-music";
// import {activeSection} from "%import%/standalone/activeSection";

document.addEventListener('DOMContentLoaded', function () {
    showPage();
    mainMenu();
    shareStory();
    music();
});

window.addEventListener('load', function () {
    // activeSection();
});


window.addEventListener('resize', function(event) {

});