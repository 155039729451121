export function music() {
    const openMusicBtn = document.getElementById('openMusicBtn');
    const closeMusicSectionBtn = document.getElementById('closeMusicSectionBtn');
    const musicSection = document.getElementById('music');

    // Select all the elements in the HTML page
    // and assign them to a variable
    let playerContainer = document.getElementById('audioPlayer');

    let now_playing = document.querySelector(".now-playing");
    // let track_art = document.querySelector(".track-art");
    let track_name = document.querySelector(".track-name");
    let track_artist = document.querySelector(".track-artist");
    let playPauseBtn = playerContainer.querySelector("#playPauseTrack");
    let prevTrackBtn = playerContainer.querySelector("#prevTrack");
    let nextTrackBtn = playerContainer.querySelector("#nextTrack");

    let seekSlider = document.querySelector("#seekSlider");
    let volumeSlider = document.querySelector("#volumeSlider");
    let curr_time = document.querySelector(".current-time");
    let total_duration = document.querySelector(".total-duration");

    // Specify globally used values
    let track_index = 0;
    let isPlaying = false;
    let updateTimer;

    // Create the audio element for the player
    let curr_track = document.createElement('audio');

    // Define the list of tracks that have to be played
    let track_list = [
        {
            name: "Lady (You Bring Me Up)",
            path: "audio/01 Lady (You Bring Me Up).mp3"
        },
        {
            name: "Symphony",
            path: "audio/02 Symphony.mp3"
        },
        {
            name: "Shipping Lanes",
            path: "audio/03 Dreams.mp3",
        },
        {
            name: "September",
            path: "audio/04 September.mp3",
        },
        {
            name: "Fading Echoes",
            path: "audio/05 Fading Echoes.mp3",
        },
        {
            name: "More Than A Woman",
            path: "audio/06 More Than A Woman.mp3",
        },
        {
            name: "More Than A Woman",
            path: "audio/6a Love Like the Ocean.mp3",
        },
        {
            name: "Fresh",
            path: "audio/07 Fresh.mp3",
        },
        {
            name: "Monday",
            path: "audio/7a Monday.mp3",
        },
        {
            name: "Talk to Me",
            path: "audio/08 Talk to Me.mp3",
        },
        {
            name: "MySweetLove 3",
            path: "audio/8a MySweetLove 3.mp3",
        },
        {
            name: "Footloose",
            path: "audio/09 Footloose.mp3",
        },
        {
            name: "Embers",
            path: "audio/9a Embers.mp3",
        },        {
            name: "American Woman",
            path: "audio/10 American Woman.mp3",
        },
        {
            name: "Harvest Moon",
            path: "audio/11 Harvest Moon.mp3",
        },
        {
            name: "One Love - People Get Ready",
            path: "audio/12 One Love - People Get Ready.mp3",
        },        {
            name: "Dancing Queen",
            path: "audio/13 Dancing Queen.mp3",
        },
        {
            name: "The Flame",
            path: "audio/14 The Flame.mp3",
        },
        {
            name: "Cherish",
            path: "audio/15 Cherish.mp3",
        },
        {
            name: "Lovely Day",
            path: "audio/16 Lovely Day.mp3",
        },
        {
            name: "What the World Needs Now Is Love",
            path: "audio/17 What the World Needs Now Is Love.mp3",
        },
        {
            name: "1111111",
            path: "audio/18 Make You Feel My Love.mp3",
        },
        {
            name: "Arrangements of Love",
            path: "audio/19 Arrangements of Love.mp3",
        },
        {
            name: "Landslide",
            path: "audio/20 Landslide.mp3",
        },        {
            name: "Rhiannon",
            path: "audio/21 Rhiannon.mp3",
        },        {
            name: "I'll Never Love Again",
            path: "audio/22 I'll Never Love Again (Extended Version).mp3",
        },        {
            name: "How Can You Mend a Broken Heart",
            path: "audio/23 How Can You Mend a Broken Heart.mp3",
        },        {
            name: "Push Through",
            path: "audio/25 Push Through  - Bonus track.mp3",
        },        {
            name: "Let It Be",
            path: "audio/26 Let It Be.mp3",
        },        {
            name: "Silent Reminders",
            path: "audio/27 Silent Reminders.mp3",
        },        {
            name: "Easy Feeling",
            path: "audio/28 Easy Feeling.mp3",
        },
    ];

    function loadTrack(track_index) {
        // Clear the previous seek timer
        clearInterval(updateTimer);
        resetValues();

        // Load a new track
        curr_track.src = track_list[track_index].path;
        curr_track.load();

        // Update details of the track
        // track_art.style.backgroundImage = "url(" + track_list[track_index].image + ")";
        track_name.textContent = track_list[track_index].name;
        // track_artist.textContent = track_list[track_index].artist;
        now_playing.textContent = "PLAYING " + (track_index + 1) + " OF " + track_list.length;

        // Set an interval of 1000 milliseconds
        // for updating the seek slider
        updateTimer = setInterval(seekUpdate, 1000);

        // Move to the next track if the current finishes playing
        // using the 'ended' event
        curr_track.addEventListener("ended", nextTrack);

        // // Apply a random background color
        //         random_bg_color();
    }

    // function random_bg_color() {
    // // Get a random number between 64 to 256
    // // (for getting lighter colors)
    //     let red = Math.floor(Math.random() * 256) + 64;
    //     let green = Math.floor(Math.random() * 256) + 64;
    //     let blue = Math.floor(Math.random() * 256) + 64;
    //
    //     // Construct a color with the given values
    //     let bgColor = "rgb(" + red + ", " + green + ", " + blue + ")";
    //
    //     // Set the background to the new color
    //     document.body.style.background = bgColor;
    // }

    // Function to reset all values to their default
    function resetValues() {
        curr_time.textContent = "00:00";
        total_duration.textContent = "00:00";
        seekSlider.value = 0;
    }


    function playPauseTrack() {
        // Switch between playing and pausing
        // depending on the current state
        if (!isPlaying) playTrack();
        else pauseTrack();
    }

    function playTrack() {
        // Replace icon with the pause icon
        playPauseBtn.innerHTML = '<svg><use xlink:href="img/sprites/sprite.svg#player-btn-pause"></use></svg>';

        // Play the loaded track
        curr_track.play().then(r => {
            isPlaying = true;
        });
    }

    function pauseTrack() {
        // Pause the loaded track
        curr_track.pause();
        isPlaying = false;

        // Replace icon with the play icon
        playPauseBtn.innerHTML = '<svg><use xlink:href="img/sprites/sprite.svg#player-btn-play"></use></svg>';
    }

    function nextTrack() {
        // Go back to the first track if the
        // current one is the last in the track list
        if (track_index < track_list.length - 1)
            track_index += 1;
        else track_index = 0;

        // Load and play the new track
        loadTrack(track_index);
        playTrack();
    }

    function prevTrack() {
        // Go back to the last track if the
        // current one is the first in the track list
        if (track_index > 0)
            track_index -= 1;
        else track_index = track_list.length - 1;

        // Load and play the new track
        loadTrack(track_index);
        playTrack();
    }

    function seekTo() {
        // Calculate the seek position by the
        // percentage of the seek slider
        // and get the relative duration to the track
        // Set the current track position to the calculated seek position
        curr_track.currentTime = curr_track.duration * (seekSlider.value / 100);
    }

    function setVolume() {
    // Set the volume according to the
    // percentage of the volume slider set
        curr_track.volume = volumeSlider.value / 100;
    }

    function seekUpdate() {
        let seekPosition = 0;

        // Check if the current track duration is a legible number
        if (!isNaN(curr_track.duration)) {
            seekPosition = curr_track.currentTime * (100 / curr_track.duration);
            seekSlider.value = seekPosition;

            // Calculate the time left and the total duration
            let currentMinutes = Math.floor(curr_track.currentTime / 60);
            let currentSeconds = Math.floor(curr_track.currentTime - currentMinutes * 60);
            let durationMinutes = Math.floor(curr_track.duration / 60);
            let durationSeconds = Math.floor(curr_track.duration - durationMinutes * 60);

            // Add a zero to the single digit time values
            if (currentSeconds < 10) {
                currentSeconds = "0" + currentSeconds;
            }
            if (durationSeconds < 10) {
                durationSeconds = "0" + durationSeconds;
            }
            if (currentMinutes < 10) {
                currentMinutes = "0" + currentMinutes;
            }
            if (durationMinutes < 10) {
                durationMinutes = "0" + durationMinutes;
            }

            // Display the updated duration
            curr_time.textContent = currentMinutes + ":" + currentSeconds;
            total_duration.textContent = durationMinutes + ":" + durationSeconds;
        }
    }

    function openMusicSection() {
        document.body.classList.add('contentLocked');
        musicSection.hidden = false;
    }

    function closeMusicSection() {
        document.body.classList.remove('contentLocked');
        musicSection.hidden = true;
    }

    playPauseBtn.addEventListener('click', playPauseTrack);
    prevTrackBtn.addEventListener('click', prevTrack);
    nextTrackBtn.addEventListener('click', nextTrack);
    seekSlider.addEventListener('change', seekTo);
    volumeSlider.addEventListener('change', setVolume);

    openMusicBtn.addEventListener('click', openMusicSection);
    closeMusicSectionBtn.addEventListener('click', closeMusicSection);

    if(window.location.hash === '#music') {
        // window.location.hash = '';
        openMusicSection();
    }

    // window.addEventListener('hashchange', function() {
    //     console.log('Ура, хэш изменился!:', location.hash);
    // });

    // Load the first track in the tracklist
    loadTrack(track_index);

}