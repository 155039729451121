export const mainMenu = () => {
    let mainMenu = document.querySelector('.main-menu');

    if(mainMenu) {
        let mobileMenuBtn = document.getElementById('mobileMenuTrigger');
        let menuItems = mainMenu.querySelectorAll('a');

        function closeMobileMenu() {
            document.body.classList.remove('mobileMenuActive', 'scrollDisable');
        }

        function openMobileMenu() {
            document.body.classList.toggle('mobileMenuActive');
            document.body.classList.toggle('scrollDisable');

        }

        menuItems.forEach((element, index) => {
            element.addEventListener('click', closeMobileMenu)
        });

        mobileMenuBtn.addEventListener('click', openMobileMenu);

        window.addEventListener('resize', closeMobileMenu);
    }
}