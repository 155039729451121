export function shareStory() {
    const shareStoryContainer = document.getElementById("shareStory");
    const shareStoryForm = document.getElementById("shareStoryForm");
    const shareStoryFormInputs = shareStoryForm.querySelectorAll('input');
    const shareStoryTitle = shareStoryContainer.querySelector('.section-title');
    const shareStoryTitleOriginal = shareStoryContainer.querySelector('.section-title').innerText;

    const openShareStoryBtn = document.querySelectorAll(".openShareStoryBtn");
    const closeShareStoryBtn = shareStoryContainer.querySelector("#closeShareStoryBtn");

    const shareStoryPreviousStep = shareStoryContainer.querySelector('#shareStoryPreviousStep');
    const shareStoryNextStep = shareStoryContainer.querySelector('#shareStoryNextStep');
    const publishStoryBtn = shareStoryContainer.querySelector('#publishStoryBtn');

    const thankYouMessage = shareStoryContainer.querySelector('#thankYouMessage');

    const counterActiveStep = shareStoryContainer.querySelector('.share-story-info-active');
    const counterTotalSteps = shareStoryContainer.querySelector('.share-story-info-total');

    let shareStepsForm = shareStoryContainer.querySelector("form");
    let shareSteps = shareStoryContainer.querySelectorAll(".share-story-step");
    let stepsLength = shareSteps.length -1;
    let activeStep = 0;

    let counter = {
        total: counterTotalSteps.innerHTML = (stepsLength +1).toString(),

        update: ()=> {
            counterActiveStep.innerHTML = (activeStep+1).toString();
        }
    }

    console.log('stepsLength', stepsLength);

    function init() {
        shareSteps[0].hidden = false;
        checkState();
    }

    function openShareStory() {
        document.body.classList.add('contentLocked');
        shareStoryContainer.hidden = false;

        init();
    }

    function closeShareStory() {
        document.body.classList.remove('contentLocked');
        console.log(activeStep)
        activeStep = 0;
        console.log(activeStep)
        shareStoryContainer.hidden = true;
        shareStepsForm.hidden = false;

        thankYouMessage.hidden = true;
        shareStoryTitle.innerText = shareStoryTitleOriginal;

        shareSteps.forEach((step) => {
            step.hidden = true;
        })
    }

    function afterSuccessSubmit() {
        shareStoryTitle.innerText = 'thank you!';
        shareStepsForm.hidden = true;
        thankYouMessage.hidden = false;

        setTimeout(closeShareStory, 5000);
    }

    //Validation variant by form elements types
    function emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function textIsValid(text) {
        // return /^[a-zA-Z\s-]+$/.test(text)
        if (text !== "") return text
    }

    function addFocusToFormElement(activeStep) {
        console.log(activeStep)
        let input = activeStep.querySelector('input');
        let textarea = activeStep.querySelector('textarea');

        if(input) {
            input.focus();
        } else if(textarea) {
            textarea.focus();
        }
    }

    function validate(activeStep) {
        console.log('Validation Start');

        let input = activeStep.querySelector('input');
        let textarea = activeStep.querySelector('textarea');

        if(input) {
            // console.log('Input')

            if (input.type === 'text') {
                console.log('Input Type Text')

                if (!textIsValid(input.value)) {
                    console.log('Please enter your Name')
                } else {
                    return true;
                }
            }

            if (input.type === 'email') {
                console.log('Input Type Email')

                if (!emailIsValid(input.value)) {
                    console.log('Please enter a valid email address.')
                } else {
                    return true;
                }
            }
        } else if(textarea) {
            // console.log('Textarea')

            if (!textIsValid(textarea.value)) {
                console.log('Type some text')
            } else {
                return true;
            }
        }

        console.log('Validation FINISH');
    }

    function checkState() {
        console.log('checkState');

        addFocusToFormElement(shareSteps[activeStep]);

        if(activeStep === 0) {
            if(shareStoryPreviousStep.hidden === false) {
                shareStoryPreviousStep.hidden = true;
            }
        }

        if(activeStep === stepsLength) {
            publishStoryBtn.hidden = false;
            publishStoryBtn.disabled = false;
            shareStoryNextStep.hidden = true;
        }

        if (activeStep !== stepsLength) {
            shareStoryNextStep.hidden = false;
            shareStoryNextStep.disabled = false;

            if(publishStoryBtn.hidden === false) {
                publishStoryBtn.hidden = true;
            }
        }

        if(activeStep !== 0) {
            shareStoryPreviousStep.hidden = false;
        }

        console.log('Active Step:', activeStep);
        counter.update()
    }

    function nextStep() {
        if(validate(shareSteps[activeStep])) {
            shareSteps[activeStep].querySelector('.form-data').classList.remove('form-data-error');

            shareSteps[activeStep].hidden = true;
            activeStep++;
            shareSteps[activeStep].hidden = false;

            checkState();
        }
        else {
            shareSteps[activeStep].querySelector('.form-data').classList.add('form-data-error');
        }
    }

    function previousStep() {
        shareSteps[activeStep].hidden = true;
        activeStep--;
        shareSteps[activeStep].hidden = false;

        checkState();
    }

    //Steps Navigation buttons listeners
    shareStoryNextStep.addEventListener('click', nextStep);
    shareStoryPreviousStep.addEventListener('click', previousStep);
    // publishStoryBtn.addEventListener('click', afterSuccessSubmit);

    //Open & Close buttons listeners
    closeShareStoryBtn.addEventListener("click", closeShareStory)
    openShareStoryBtn.forEach((btn) => {
        btn.addEventListener("click", openShareStory)
    });

    if(window.location.hash === '#shareStory') {
        openShareStory();
        afterSuccessSubmit();
        // window.location.hash = '';
        let uri = window.location.toString();

        if (uri.indexOf("#") > 0) {
            let clean_uri = uri.substring(0, uri.indexOf("#"));

            window.history.replaceState({},
                document.title, clean_uri);
        }
    }
}